import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./landing.module.scss";
import background from "./assets/background_landing.png";
import background_2 from "./assets/background_2.png";
import lines from "./assets/lines_background.png";
import lines2 from "./assets/lines_background_2.png";
import window_1 from "./assets/window_1.png";
import window_2 from "./assets/window_2.png";
import window_3 from "./assets/window_3.png";
import window_4 from "./assets/window_4.png";
import window_5 from "./assets/window_5.png";
import icon_aviao from "./assets/aviao.svg";
import icon_hotel from "./assets/hotel.svg";
import icon_camera from "./assets/camera.svg";
import icon_lua from "./assets/lua.svg";
import clock from "./assets/clock.svg";
//import radio_comercial from "../../assets/radio_comercial.png";
import vasco_palmeirim from "./assets/vasco_palmeirim.png";
import nuno_markl from "./assets/nuno_markl.png";
import rita_rugeroni from "./assets/rita_rugeroni.png";
import ana_aroja from "./assets/ana_aroja.png";
import {useApp} from "../../AppProvider";
import {get} from "../../helpers";
import Marquee from "react-fast-marquee";


export default function Index({}) {
    const app = useApp();
    const [paddingTop, setPaddingTop] = useState(80);//for the element play-the-game
    const [howToPlayElement, setHowToPlayElement] = useState(null);
    const [mostVoted, setMostVoted] = useState(null);
    const oscaresDate = new Date("2024-03-10T23:00:00");
    const [timeLeft, setTimeLeft] = useState((oscaresDate.getTime() - new Date().getTime()) / 1000);
    const [playMarquee, setPlayMarquee] = useState(null);


    const radioComercialPersonalities = [
        {
            name: "Vasco Palmeirim",
            photo: vasco_palmeirim,
            text: <p>«Não sei tanto como o Markl, mas quero muito que <b>Os Excluídos</b> ganhem tudo o que houver para ganhar»</p>,
            video: null
        },
        {
            name: "Ana Aroja",
            photo: ana_aroja,
            text: <p>«Sem dúvidas: a performance da Lily Gladstone em <b>Assassinos da Lua das Flores</b> merece o Óscar.»</p>
        },
        {
            name: "Rita Rugeroni",
            photo: rita_rugeroni,
            text: <p>«A categoria de Melhor Filme de Animação está renhida, bem sei, mas eu aposto <b>n’O Rapaz e a Garça</b>».</p>
        },
        {
            name: "Nuno Markl",
            photo: nuno_markl,
            text: <p>«<b>Oppenheimer</b> é um titã de filme. Se não ganhar Melhor Realização (e citando Jennifer Lawrence): I’m leaving»</p>
        }
    ]

    useEffect(() => {
        const updatePadding = () => {
            howToPlayElement && setPaddingTop(howToPlayElement.offsetHeight);
        };
        setTimeout(updatePadding, 200) //wait for the element to be rendered...it worked
        window.addEventListener('resize', updatePadding);
        return () => {
            window.removeEventListener('resize', updatePadding);
        };
    }, [howToPlayElement]);

    useEffect(() => {
        get(`quiz/${app.data?.quiz?.id}/most-voted-answers`).then(res => setMostVoted(res.most_voted_answers)).catch(e => console.error("error getting most voted answers", e))
    }, []);


    useEffect(() => {
        if (timeLeft <= 0) {
            return;
        }

        // Set up the timer
        const timer = setInterval(() => {
            setTimeLeft((prevSeconds) => prevSeconds - 1);
        }, 1000);

        // Clean up the timer
        return () => clearInterval(timer);
    }, [timeLeft]);

    const formatTimeLeft = () => {
        //return in days, hours, minutes and seconds
        let days = Math.floor(timeLeft / (60 * 60 * 24));
        let hours = Math.floor((timeLeft / (60 * 60)) % 24);
        let minutes = Math.floor((timeLeft / 60) % 60);
        let seconds = Math.floor(timeLeft % 60);
        if (hours < 10)
            hours = "0" + hours;
        if (minutes < 10)
            minutes = "0" + minutes;
        if (seconds < 10)
            seconds = "0" + seconds;
        return `${days} dias ${hours} horas e ${minutes} minutos`;
    }

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };


    return <div className={styles.Landing}>
        <div className={"hero"} style={{backgroundImage: `url(${background})`}}>
            <div className={"container"}>
                <img className={"window-1"} src={window_1} alt={"window_1"}/>
                <div className={"hero-copy"}>
                    <div className={"title"}>
                        {/*<h1>ACERTA</h1>*/}
                        {/*<h1>E LEVANTA <br className={"hide-mobile"}/> VOO</h1>*/}
                        <h1>BOA</h1>
                        <h1>VIAGEM,<br className={"hide-mobile"}/> JOÃO PINTO</h1>
                    </div>
                    {/*<p className={"subtitle simple-text"}>Adivinha os vencedores dos Óscares e habilita-te a voar com a <strong>Hollywood Airlines até Los Angeles.</strong></p>*/}
                    {/*<p className={"subtitle simple-text"}>A postos para a viagem? Os resultados do passatempo Palpites dos Óscares 2024 serão anunciados em breve.<br/>Boa sorte!</p>*/}
                    <p className={"subtitle simple-text"}>Com os 21 palpites mais certeiros — e mais rápidos —, foste o grande vencedor do passatempo Palpites dos Óscares 2024.<br/><strong>O Canal Hollywood agradece a todos os que participaram. Até para o ano (que o cinema esteja convosco)!</strong></p>
                    <div className={"buttons"}>
                        {/*<button id={"element-id"} className={"button"} onClick={() => app.showRegister()}>registar</button>*/}
                        {/*<button className={"button"} onClick={() => app.showLogin()}>ENTRAR</button>*/}
                    </div>
                </div>
                <img className={"window-2"} src={window_2} alt={"window_2"}/>
            </div>
        </div>
        <div className={"most-voted"}>
        {/*<div className={"month-label"}>*/}
            {/*    <Marquee autoFill={false} speed={70}><h3>FALTAM {formatTimeLeft()} PARA OS ÓSCARES</h3></Marquee>*/}
            {/*</div>*/}
            <div className={"content container-medium"}>
                <div className={"content__title"}>
                    <h2 style={{marginTop: "-0.05em"}}>Palpites<br/>populares</h2>
                    <p>Se não sabes quem<br/> escolher, vê o que está a dar que falar.</p>
                </div>
                <div className={"content__votes"}>
                    <div className={"content__votes-label"}>
                        <h4 className={"percentage"}>Votos</h4>
                        <h4 className={"category"}>Categoria</h4>
                        <h4 className={"name"}>Nome</h4>
                    </div>
                    {mostVoted ? mostVoted.map((mv, index) => {
                        let [answer, movie] = mv.answer_text.split("_");
                        const answerText = answer.trim() + (movie ? ` (${movie})` : "");
                        return (
                            <div className={"content__votes-item"} key={"most-voted-" + index}>
                                <p className={"percentage"}>{Math.round(mv.percentage)}%</p>
                                <p className={"category"}>{mv.question_text}</p>
                                <p className={"name hide-mobile"} onMouseEnter={() => setPlayMarquee(mv.question_id)} onMouseLeave={() => setPlayMarquee(null)}><Marquee play={playMarquee === mv.question_id}>{answerText}</Marquee></p>
                                <p className={"name hide-desktop"}>{answer.trim()}</p>
                                {movie && <p className={"name hide-desktop"}>({movie})</p>}
                            </div>)
                    }) : <div className={"loading-container"}>
                        <div className="lds-ripple">
                            <div/>
                            <div/>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <div className={"prize"}>
            <img className={"background-image"} src={lines} alt={"lines"}/>
            <img src={window_3} alt={"window_3"} className={"window-image"}/>
            <div className={"content container-medium"}>
                <img src={window_5} alt={"window_5"} className={"window-image-mobile"}/>
                <div className={"title"}>{["GANHA", "UMA", "VIAGEM", "A L.A."].map(palavra => <h1 key={palavra}>{palavra}</h1>)}</div>
                <div className={"subtitle simple-text"}>Se estás o ano inteiro à espera do dia mais cinéfilo do ano — e sempre sonhaste ir a Hollywood — este prémio é para ti. <strong>A viagem dos Palpites dos Óscares está marcada: só tens de fazer o check-in e entrar a bordo.</strong></div>
                <div className={"trip-details"}>
                    <div className={"info-line"}>
                        <img src={icon_aviao} alt={"icon_aviao"}/>
                        <p>2 PAX</p>
                    </div>
                    <div className={"info-line"}>
                        <img src={icon_hotel} alt={"icon_hotel"}/>
                        <p>HOTEL 4*</p>
                    </div>
                    <div className={"info-line"}>
                        <img src={icon_lua} alt={"icon_lua"}/>
                        <p>5 NOITES</p>
                    </div>
                    <div className={"info-line"}>
                        <img src={icon_camera} alt={"icon_camera"}/>
                        <div>
                            <p>Estúdio</p>
                            <p>Warner Bros. 2x</p>
                        </div>
                    </div>
                    {/*<button className={"button"} onClick={() => app.showRegister()}>registar</button>*/}
                </div>
            </div>
        </div>
        <div className={"play-the-game"} style={{backgroundImage: `url(${background_2})`, paddingTop: paddingTop - 50}}>
            <div className={"how-to-play"} ref={setHowToPlayElement} style={{backgroundImage: `url(${lines2})`}}>
                <div className={"container-large"}>
                    <div className={"label"}>
                        <h2>Como participar</h2>
                        <p className={"simple-text text-white"}>Queres descolar?<br/>Só tens de escolher. Há um destino e duas formas de lá chegar: <strong>regular track (os teus palpites, ao teu ritmo) ou fast track (a nossa sugestão de palpites, ao nosso ritmo).</strong></p>
                    </div>
                    <div className={"steps-container"}>
                        <div className={"info"}>
                            <h3>1</h3>
                            <h5>23 categorias</h5>
                            <p>Aviso à navegação:<br/>do Melhor Filme à Melhor Fotografia, são 23 vencedores para adivinhar.</p>
                        </div>
                        <div className={"info"}>
                            <h3>2</h3>
                            <h5>23 palpites</h5>
                            <p>Quantas mais categorias acertares, mais hipóteses tens de aterrar em L.A..</p>
                        </div>
                        <div className={"info"}>
                            <h3>3</h3>
                            <h5>2 modalidades</h5>
                            <p>Confiante COM AS TUAS ESCOLHAS?<br/> Boa sorte! Se (ainda) não estás contente, experimenta a SUGESTÃO DE PALPITES.</p>
                            <a className={"button grey"} href={"https://canalhollywood.pt/wp-content/uploads/2024/01/REGULAMENTO_PALPITES2024.pdf"} target={"_blank"}>VER REGULAMENTO</a>
                            <a className={"button grey"} href={"https://canalhollywood.pt/wp-content/uploads/2024/01/Palpites-dos-Oscares-2024-Faqs.pdf"} target={"_blank"}>VER FAQS</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"container container-large"}>
                <div>
                    <h3 className={"text-white"}>Sabes Tudo?</h3>
                    <h3 className={"text-white"}>Vamos escolher.</h3>
                    <p className={"simple-text"}>Viste todos os filmes e és o/a cinéfilo/a do grupo?<br/><strong>Então, participa e toca a escolher.</strong></p>
                    <div className={"clock-time"}>
                        <img src={clock} alt={"clock"}/>
                        <p>REGULAR TRACK</p>
                    </div>
                    {/*<button className={"button"} onClick={() => app.showRegister()}>registar</button>*/}
                </div>
                <img src={window_4} alt={"window_4"}/>
                <div>
                    <h3 className={"text-white"}>SÓ SABES QUE</h3>
                    <h3 className={"text-white"}>QUERES IR A L.A.?</h3>
                    <p className={"simple-text"}>Não viste nenhum filme, mas queres viajar? <strong>Escolhe a opção «Sugestão de Palpites» e arrisca-te a ganhar.</strong></p>
                    <div className={"clock-time"}>
                        <img src={clock} alt={"clock"}/>
                        <p>FAST TRACK</p>
                    </div>
                    {/*<button className={"button"} onClick={() => app.showRegister(true)}>registar</button>*/}
                </div>
            </div>
        </div>
        {/*<div className={"radio-comercial"}>*/}
        {/*    <div className={"container-large"}>*/}
        {/*        <div className={"apoio-de"}>*/}
        {/*            <h2 className={"text-white"}>Com o<br/>apoio de:</h2>*/}
        {/*            <div className={"fit-title"}>*/}
        {/*                <img src={radio_comercial} alt={"radio_comercial"}/>*/}
        {/*            </div>*/}
        {/*            <p>Será que os animadores de rádio mais queridos do país têm os palpites mais certeiros do mundo? Inspira-te neles.</p>*/}
        {/*            <a href={"https://radiocomercial.pt"} target={"_blank"}>radiocomercial.pt</a>*/}
        {/*        </div>*/}
        {/*        <div className={"personalities"}>*/}
        {/*            {radioComercialPersonalities.map(personality => <div className={"personality-card"}>*/}
        {/*                <div className={"photo-container" + (personality.video ? " with_video" : "")} style={{backgroundImage: `url(${personality.photo})`}}/>*/}
        {/*                <h4>{personality.name}</h4>*/}
        {/*                {personality.text}*/}
        {/*            </div>)}*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className={"personalities-mobile"}>*/}
        {/*        <Slider {...sliderSettings}>*/}
        {/*            {radioComercialPersonalities.map(personality => <div className={"personality-card"}>*/}
        {/*                <div className={"photo-container" + (personality.video ? " with_video" : "")} onClick={() => personality.video && setPlayingVideo(true)} style={{backgroundImage: `url(${personality.photo})`}}/>*/}
        {/*                <h5>{personality.name}</h5>*/}
        {/*                {personality.text}*/}
        {/*            </div>)}*/}
        {/*        </Slider>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className={"footer"}>
            <div className={"container-large"}>
                <h3 className={"text-white"}>Tens dúvidas?</h3>
                <p className={"contact-us"}>
                    Envia-nos um e-mail para:
                    <br/><a href={"mailto:apoioaocliente@palpitesdososcares.pt"}>apoioaocliente@palpitesdososcares.pt</a>
                </p>
            </div>
        </div>
    </div>;
}