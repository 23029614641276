import icon_facebook from "../../assets/icons/facebook_icon.svg";
import icon_whatsapp from "../../assets/icons/whatsapp_icon.svg";
import icon_email from "../../assets/icons/email.svg";
import icon_share from "../../assets/icons/share_icon.svg";
import icon_x from "../../assets/icons/x.svg";
import {useApp} from "../../AppProvider";
import styles from './social.module.scss';
import copy_regular from './copy-regular.svg';
import {useState} from "react";

export default function Social() {
    const app = useApp();

    const url = app.data.base_url + "?referral=" + app.data.user.referral_code;

    const shareText = "Achas que sabes quem vai ganhar os Óscares? Então, prepara as malas, dá os teus palpites e habilita-te a ganhar uma viagem para Los Angeles para 2 pessoas, com tudo incluído. Só tens de clicar no link em baixo e tentar acertar na maior quantidade de vencedores. Depois? É descolar.\n" +
        "\n" +
        "Mas há mais: partilha o link com outros amigos e ganha até 3 respostas extra.\n" +
        "\n" +
        "Bom voo!" +
        "\n" +
        "Link: " + url;

    const smallShareText = "Sabes quem vai ganhar os Óscares? Então, dá os teus palpites e habilita-te a ganhar uma viagem para Los Angeles para 2 pessoas, com tudo incluído.\n Se partilhares o link com mais amigos, ganhas até 3 respostas extra.\n " + url;

    return <div className={styles.Social + " social"}>
        <a target={"_blank"} href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}><img src={icon_facebook} alt={"icon_facebook"}/></a>
        <a target={"_blank"} href={"https://wa.me/?text=" + shareText}><img src={icon_whatsapp} alt={"icon_whatsapp"}/></a>
        {/*<a target={"_blank"} href={"mailto:?subject=" + encodeURI("Passadeira para LA") + "&body=" + encodeURI(*/}
        {/*    "Achas que sabes quem vai ganhar os Óscares? Então, estende a passadeira às tuas apostas e habilita-te a ganhar uma viagem para Los Angeles para 2 pessoas, com tudo incluído. Só tens de clicar no link ali em baixo e tentar acertar na maior quantidade de vencedores.\n\n" +*/}
        {/*    "Mas há mais: partilha o teu link com outros amigos e ganha até 3 respostas extra. \n\n" +*/}
        {/*    "Boas apostas!\n\n" +*/}
        {/*    url*/}
        {/*)}><img src={icon_email}/></a>*/}
        <a target={"_blank"} href={"https://twitter.com/intent/tweet?text=" + smallShareText}><img src={icon_x} alt={"icon_x"}/></a>
        <a target={"_blank"} onClick={() => {
            let Component = () => {
                const [copied, setCopied] = useState(false);

                return <div className={"popup"}>
                <span className={"close"} onClick={() => {
                    app.setPopup(null);
                    setCopied(false);
                }}/>
                    <h4>Partilhar</h4>
                    <p>Partilha o seguinte link com os teus amigos para ganhar mais votos:<br/>
                        <span
                            onClick={() => {
                                navigator.clipboard.writeText(url);
                                setCopied(true);
                            }}
                            style={{cursor: "pointer"}}
                        >{url} <img src={copy_regular} style={{width: 20}}/></span></p>
                    {copied && <p>Link copiado</p>}
                    <div className={"bottom"}>
                        <button className={"button"} onClick={() => {
                            app.setPopup(null);
                            setCopied(false);
                        }}>Fechar
                        </button>
                    </div>
                </div>
            };
            app.setPopup(<Component/>)
        }}><img src={icon_share}/></a>
    </div>
}