import React, {useRef, useState} from 'react';
import {get, post} from "./helpers";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import axios from "axios";

const Context = React.createContext({});
const useApp = () => React.useContext(Context);

localStorage.getItem("token_palpites");
if (localStorage.getItem("token_palpites"))
    axios.defaults.headers.common = {...axios.defaults.headers.common, 'Authorization': `Bearer ${localStorage.getItem("token_palpites")}`};

export default function AppProvider(props) {
    const [data, setData] = useState(null);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [randomParticipation, setRandomParticipation] = useState(false); // used to show the register popup after clicking the random button
    const [gameOpen, setGameOpen] = useState(false);
    const requesting = useRef(false);
    const [loading, setLoading] = useState(true);
    const [popup, setPopup] = useState(null);

    React.useEffect(() => {
        (async () => {
            const url = new URL(window.location.href);
            let magiclink = url.searchParams.get("magiclink");

            if (magiclink) {
                url.searchParams.delete("magiclink");
                window.history.replaceState(null, null, url);

                if (magiclink === "invalid_token") {
                    setPopup(<>
                        <h3>Login inválido</h3>
                        <p>O link utilizado já foi utilizado ou já expirou. Faça login novamente.</p>
                        <a className={"button"} onClick={() => setPopup(null)}>Fechar</a>
                    </>);
                } else {
                    await get("magiclink/" + magiclink).then(data => {
                        if (data.token) {
                            localStorage.setItem("token_palpites", data.token);
                            axios.defaults.headers.common = {'Authorization': `bearer ${data.token}`};
                        }
                    }).catch(e => {
                        console.error(e);
                        if (e.response.status === 401)
                            setPopup(<>
                                <h3>Login inválido</h3>
                                <p>O link utilizado já foi utilizado ou já expirou. Faça login novamente.</p>
                                <a className={"button"} onClick={() => setPopup(null)}>Fechar</a>
                            </>);
                    });
                }
            }
            get("quiz").then(setData).finally(() => setLoading(false));
        })();
    }, [])

    async function login(values) {
        if (requesting.current)
            return;
        requesting.current = true;
        try {
            await post("auth/login", values);
            requesting.current = false;
        } catch (e) {
            requesting.current = false;
            throw e.response?.data || e;
        }
    }

    async function logout() {
        if (requesting.current)
            return;
        requesting.current = true;
        try {
            localStorage.removeItem("token_palpites");
            delete axios.defaults.headers.common.Authorization;
            setGameOpen(false);
            await get("quiz").then(setData);
            requesting.current = false;
        } catch (e) {
            requesting.current = false;
            throw e.response?.data || e;
        }
    }

    async function register(values) {
        if (requesting.current)
            return;
        requesting.current = true;
        try {
            values.randomParticipation = randomParticipation;
            let data = await post("auth/register", values);
            if (data.token) {
                localStorage.setItem("token_palpites", data.token);
                axios.defaults.headers.common = {'Authorization': `bearer ${data.token}`};
                setShowRegister(false);
            }
            await get("quiz").then(setData);
            requesting.current = false;
        } catch (e) {
            requesting.current = false;
            throw e.response?.data || e;
        }
    }

    async function submit() {
        if (requesting.current)
            return;
        requesting.current = true;
        setLoading(true);

        try {
            let res = await post(`quiz/${data.quiz.id}/submit`);
            setData(res);

            setPopup(<>
                <h3>Votação submetida</h3>
                <p>Enviámos-te um e-mail com as tuas escolhas.</p>
                <a className={"button"} onClick={() => setPopup(null)}>Fechar</a>
            </>);

            requesting.current = false;
            setLoading(false);
            setGameOpen(false);
        } catch (e) {
            requesting.current = false;
            setLoading(false);

            if (e.response?.data?.message)
                setPopup(<>
                    <h3>Ocorreu um erro</h3>
                    <p>{e.response?.data?.message}</p>
                    <a className={"button"} onClick={() => setPopup(null)}>Fechar</a>
                </>);

            throw e.response?.data || e;
        }
    }

    async function submitQuiz(openQuiz) {
        setPopup(<div className={"popup"}>
            <span className={"close"} onClick={() => setPopup(null)}/>
            <h3>Atenção</h3>
            <p style={{marginTop: "15px"}}>Depois de submeteres a participação, não podes alterar a tua votação. <br className={"hide-mobile"}/>E não te esqueças: se partilhares o desafio e conseguires uma participação, podes ganhar até 3 respostas extra.</p>
            <div className={"bottom"}>
                <button className={"button"} style={{marginRight: 20}}
                        onClick={() => {
                            setPopup(null);
                            if (openQuiz)
                                setGameOpen(true)
                        }}>{openQuiz ? "Alterar votação" : "Cancelar"}
                </button>
                <button className={"button"}
                        onClick={async () => {
                            await submit();
                        }}>Submeter
                </button>
            </div>
        </div>);
    }

    async function generateRandomChoices(showWarning = false) {
        if (requesting.current)
            return;
        if (showWarning) {
            setPopup(<div className={"popup"}>
                <span className={"close"} onClick={() => setPopup(null)}/>
                <h3>Atenção</h3>
                <p style={{marginTop: "20px"}}>Se mudares agora, vais perder os teus palpites.</p>
                <div className={"bottom"}>
                    <button className={"button"} style={{marginRight: 20}}
                            onClick={() => {
                                setPopup(null);
                            }}>Cancelar
                    </button>
                    <button className={"button"}
                            onClick={async () => {
                                await generateRandomChoices();
                                setPopup(null)
                            }}>Obter sugestão
                    </button>
                </div>
            </div>);
            return;
        }
        requesting.current = true;
        setLoading(true);

        try {
            let res = await post(`quiz/${data.quiz.id}/generateRandom`);
            setData(res);

            requesting.current = false;
            setLoading(false);
            setGameOpen(false);
        } catch (e) {
            requesting.current = false;
            setLoading(false);

            if (e.response?.data?.message)
                setPopup(<>
                    <h3>Ocorreu um erro</h3>
                    <p>{e.response?.data?.message}</p>
                    <a className={"button"} onClick={() => setPopup(null)}>Fechar</a>
                </>);

            throw e.response?.data || e;
        }
    }

    async function startNormalTrack() {
        if (requesting.current)
            return;
        requesting.current = true;
        setLoading(true);

        try {
            let res = await post(`quiz/${data.quiz.id}/startNormalTrack`);
            setData(res);

            requesting.current = false;
            setLoading(false);
            setGameOpen(false);
        } catch (e) {
            requesting.current = false;
            setLoading(false);

            if (e.response?.data?.message)
                setPopup(<>
                    <h3>Ocorreu um erro</h3>
                    <p>{e.response?.data?.message}</p>
                    <a className={"button"} onClick={() => setPopup(null)}>Fechar</a>
                </>);

            throw e.response?.data || e;
        }
    }

    const values = {
        data,
        login, logout, showLogin: () => setShowLogin(true), hideLogin: () => setShowLogin(false),
        register, showRegister: (fromRandomButton) => {setShowRegister(true);setRandomParticipation(fromRandomButton)}, hideRegister: () => setShowRegister(false),
        setGameOpen, gameOpen,
        submitQuiz,
        generateRandomChoices,
        startNormalTrack,
        setPopup,
        setLoading
    };

    return <Context.Provider value={values}>
        {props.children}
        {showLogin && <Login/>}
        {showRegister && <Register/>}
        {popup && <div className={"app_popup"} onClick={!loading && (() => setPopup(false))}>
            <div onClick={e => e.stopPropagation()}>{popup}
                <div className={"loading" + (loading ? " visible" : "")}>
                    <div className="lds-ripple">
                        <div/>
                        <div/>
                    </div>
                </div>
            </div>
        </div>}
        {loading && !popup && <div className={"app_loading"}>
            <div className="lds-ripple">
                <div/>
                <div/>
            </div>
        </div>}
    </Context.Provider>
}

export {useApp};