import styles from './auth.module.scss';
import {useApp} from "../../AppProvider";
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import lines_background from "../../assets/lines_background_3.png";

export default function Login() {
    let app = useApp();
    let {register, handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [success, setSuccess] = useState(false);

    async function login(values) {
        setLoading(true);
        setServerError(false);
        try {
            await app.login(values);
            setSuccess({email: values.email});
        } catch (e) {
            setServerError(e.message);
        }
        setLoading(false);
    }

    let content = <React.Fragment key={"login"}>
        <h3 style={{marginBottom: "30px"}}>Escolhe os <br/>vencedores</h3>
        <label data-error={errors.email?.message}>
            <input {...register("email", {
                required: "O e-mail é obrigatório",
                pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "O e-mail não é válido"
                }
            })} type="email" placeholder={"E-mail"}/>
        </label>
        {!app.data.quiz.ended && <p>Se ainda não estás registado, regista-te <a onClick={() => {
            app.hideLogin();
            app.showRegister()
        }}>aqui</a>.</p>}
        <div className={"bottom"}>
            <button className={"button"} onClick={handleSubmit(login)}>Entrar</button>
        </div>
        {serverError && <p className={"error"}>{serverError}</p>}
    </React.Fragment>

    if (success)
        content = <React.Fragment key={"sucesso"}>
            <h2>Está quase</h2>
            <p className={"mt-1"}>Verifica o teu e-mail ({success.email}) para aceder à votação</p>
        </React.Fragment>

    return <div className={styles.Auth} onClick={app.hideLogin}>
        <div className={"form"} style={{backgroundImage: `url(${lines_background})`}} onClick={e => e.stopPropagation()}>
            <div className={"loading" + (loading ? " visible" : "")}>
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <span className={"close"} onClick={app.hideLogin}/>
            {content}
        </div>
    </div>
}