import styles from "./dashboard.module.scss";
import {useApp} from "../../AppProvider";
import groupArray from "../../misc/groupArray";
import Social from "../../components/social";
import window1 from "../landing/assets/window_1.png";

export default function Dashboard({questions}) {
    const app = useApp();

    const remaining = questions.filter(q => !q.answered).length;
    const referral_remaining = 3 - app.data.referral_count;

    const answersToGive = questions.length + app.data.referral_count

    const {user} = app.data;

    return <div className={styles.Dashboard}>
        <div className={"header"}>
            <p><strong>Olá,</strong> {app.data.user.name}</p>
            <div className={"actions"}>
                <a href={"https://canalhollywood.pt/wp-content/uploads/2024/01/REGULAMENTO_PALPITES2024.pdf"} target={"_blank"}>Ver regulamento</a>
                <a href={"mailto:apoioaocliente@palpitesdososcares.pt"}>apoioaocliente@palpitesdososcares.pt</a>
                <a onClick={app.logout}>Sair</a>
            </div>
        </div>
        <div className={"main"}>
            <div>
                {app.data.participation?.ended_at ?
                    <h5><strong>Já estás mais perto de L.A.</strong></h5>
                    : (app.data.quiz.ended ?
                            <h5>O passatempo terminou</h5> :
                            (user.random_participation ?
                                <h5 style={{marginBottom: "50px"}}>Bem-vindo ao Fast Track.<br/>Confere a nossa Sugestão de Palpites.</h5> :
                                <h5>Tens {answersToGive} {answersToGive === 1 ? "resposta" : "respostas"} para dar.
                                    {referral_remaining > 0 && <><br/>Partilha com {referral_remaining} amigo{referral_remaining === 1 ? "" : "s"} e ganha mais {referral_remaining}.</>}
                                </h5>)
                )}
                {!user?.random_participation && <Social/>}
                {app.data.participation?.ended_at ? <p>
                    Agora, só tens de esperar até ao final da cerimónia para saber como te saíste. <br className={"hide-mobile"}/>
                    Em março, anunciamos o grande vencedor. A melhor parte? <br className={"hide-mobile"}/>
                    Podes ser tu (e a tua companhia).
                </p> : (app.data.quiz.ended ?
                    <p>Em breve, anunciamos o grande vencedor.</p> :
                    <>
                        <h3>ACERTA</h3>
                        <h3>E LEVANTA VOO</h3>
                    <p style={{maxWidth: "90%"}}>23 categorias, 23 palpites — tens o que é preciso para vo(t)ar?
                        Há duas formas: Regular track (palpites que podes inserir de forma manual, ao teu ritmo) ou Fast track (palpites que a plataforma gera por ti, em apenas alguns minutos).
                        Só precisas de fazer o check-in, escolher e descolar — com a Hollywood Airlines.
                    </p>
                    </>)}

            </div>
            <img className={"hide-mobile"} src={window1} alt={"window-image"}/>
        </div>
        {remaining !== questions.length && <div>
            <h3>{user.random_participation ? "Sugestão de palpites" : "Os teus palpites"}</h3>
            <div className={"choices"}>
                {groupArray(questions.map((q, idx) => ({...q, idx})), 2).map(group => <div>
                    {group.map(question => <p className={question.answered ? "voted" : ""} onClick={!app.data.participation?.ended_at && (() => app.setGameOpen(question.idx))}>
                        <span>{question.idx + 1}.</span> {question.text}
                        {question.answered && <> - {question.answer?.map(a => a.text.replace("_", " / ")).join(", ")}</>}
                    </p>)}
                </div>)}
            </div>
        </div>}
        {!app.data.quiz.ended && (!app.data.participation?.ended_at && <>{(remaining === 0 && !app.data.participation?.ended_at ? <div className={"footer"}>
            {!!user?.random_participation && <div>
                <h4>Não estás satisfeito?</h4>
                <a className={"button"} onClick={() => app.generateRandomChoices()}>Nova sugestão</a>
            </div>}
            {!user?.random_participation && <div>
                <h4>Mudaste de ideias?</h4>
                <a className={"button"} onClick={() => app.setGameOpen(true)}>Alterar votação</a>
            </div>}
            <div>
                <h4>Pronto/a para descolar?</h4>
                <a className={"button"} onClick={() => app.submitQuiz(true)}>Submeter votação</a>
            </div>
            {!!user?.random_participation && <div>
                <h4>Quero escolher os meus Palpites</h4>
                <a className={"button"} onClick={app.startNormalTrack}>Regular track</a>
            </div>}
        </div> : <div className={"footer"}>
            <div><a className={"button"} style={{marginTop: "auto"}} onClick={() => app.setGameOpen(true)}>{remaining === questions.length ? 'Avançar' : 'Continuar desafio'}</a></div>
            {!user?.random_participation && <div>
                <h4>Quero uma sugestão de Palpites</h4>
                <a className={"button"} onClick={() => app.generateRandomChoices(remaining < questions.length)}>Fast Track</a>
            </div>}
        </div>)}</>)}
        <img className={"hide-desktop"} src={window1} alt={"window-image"}/>
    </div>
}