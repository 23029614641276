import axios from 'axios';

axios.defaults.withCredentials = true;

let base = (process.env.REACT_APP_BASE || '').replace(/\/+$/, '') + "/";

export async function get(url, params = null) {
    let result = await axios.get(base + url.replace(/^\/+/, ''), {params});
    return result.data;
}

export async function post(url, data = null) {
    let result = await axios.post(base + url.replace(/^\/+/, ''), data);
    return result.data;
}